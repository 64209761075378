import React from "react";

function ContactMe() {
    return (
        <div class="contactme">
            <h4>Contact me</h4>
            <a href="mailto:twthorsen@proton.me">✉️E-mail: twthorsen@proton.me</a>
        </div>
    );
}

export default ContactMe;